<template>
  <div>
    <WidgetClassReport></WidgetClassReport>
  </div>
</template>

<script>
import WidgetClassReport from "../widgets/WidgetClassReport";
export default {
  name: 'Colors',
  components: { WidgetClassReport}
}
</script>
