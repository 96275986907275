<template>
  <CRow v-if="classInfo">
    <CCol md="12">
      <CCard>
        <CCardHeader class="bg-secondary h3"><i class="ri-printer-line ri-lg"></i>&nbsp
          รายงานคะแนนพฤติกรรม</CCardHeader>
        <form id="formSearchClassroom" v-on:submit.prevent="getClassroomStat" method="POST">
          <CCardBody>
            <CRow>

              <CCol lg="6">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                    มัธยมศึกษาปีที่:
                  </label>
                  <select name="levelCode" class="form-control" required>
                    <option value="">-----เลือก-----</option>
                    <option v-for="level in levelInfo" :key="level.id" :value="level.level_id">
                      {{ level.level_fullName }}
                    </option>
                  </select>
                </div>
              </CCol>
              <CCol lg="6">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                    ห้อง:
                  </label>
                  <select name="stdClass" class="form-control" required>
                    <option value="">-----เลือก-----</option>
                    <option v-for="className in classInfo" :key="className.id" :value="className.std_class">
                      {{ className.std_class }}
                    </option>
                  </select>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter class="text-right">
            <button class="btn btn-success">
              <i class="ri-file-search-line"></i>
            </button>
          </CCardFooter>
        </form>
      </CCard>
    </CCol>
    <CCol md="12" v-if="sumData">
      <CCard>
        <CCardHeader>
          <div class="text-right">
            <button class="btn btn-dark" @click="printD" style="margin-right:10px">
              <i class="ri-printer-line ri-lg"></i>
            </button>
            <button @click="onExport" class="btn btn-secondary">Export</button>
          </div>
        </CCardHeader>
        <CCardBody class="table-responsive" id="single-info">
          <h4 class="text-center">
            รายงานคะแนนพฤติกรรม โรงเรียน{{ userData.school_name }}<br />
            ชั้น {{ sumData.mainData[5].level_abv_name }}/{{ sumData.mainData[5].std_class }} </h4>
          <table class="table table-hover">
            <thead>
              <tr class="text-small">
                <th>
                  <div class="text-center">เลขที่</div>
                </th>
                <th>
                  <div class="text-center">เลขประจำตัว</div>
                </th>
                <th>
                  <div class="text-center">ชื่อ - นามสกุล</div>
                </th>
                <th>
                  <div class="text-center">คะแนนพฤติกรรม</div>
                </th>
                <th>
                  <div class="text-center">เลขที่</div>
                </th>
                <th>
                  <div class="text-center">เลขประจำตัว</div>
                </th>
                <th>
                  <div class="text-center">ชื่อ - นามสกุล</div>
                </th>
                <th>
                  <div class="text-center">คะแนนพฤติกรรม</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="n in 30" :key="n.id" class="text-small">
                <td>
                  <div class="text-center"><strong>{{ n }}</strong></div>
                </td>
                <td>
                  <div v-if="sumData.mainData[n - 1]" class="text-center">
                    <router-link :to="'/statByStudent/' + sumData.mainData[n - 1].stud_id">
                      {{ sumData.mainData[n - 1].stud_id }}
                    </router-link>
                  </div>
                </td>
                <td>
                  <div v-if="sumData.mainData[n - 1]" class="text-std-name">
                    {{ sumData.mainData[n - 1].std_title
                    }}{{ sumData.mainData[n - 1].std_name }}
                    {{ sumData.mainData[n - 1].std_surname }}
                  </div>
                </td>
                <td>
                  <div v-if="sumData.mainData[n - 1]">
                    <div v-if="sumData.mainData[n - 1].totalScore < 80" class="text-right alert-danger">
                      {{ sumData.mainData[n - 1].totalScore }}
                    </div>
                    <div v-else class="text-right">
                      {{ sumData.mainData[n - 1].totalScore }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="text-center"><strong>{{ n + 30 }}</strong></div>
                </td>
                <td>
                  <div v-if="sumData.mainData[n + 29]" class="text-center">
                    <router-link :to="'/statByStudent/' + sumData.mainData[n + 29].stud_id">
                      {{ sumData.mainData[n + 29].stud_id }}
                    </router-link>
                  </div>
                </td>
                <td>
                  <div v-if="sumData.mainData[n + 29]" class="text-left">
                    {{ sumData.mainData[n + 29].std_title
                    }}{{ sumData.mainData[n + 29].std_name }}
                    {{ sumData.mainData[n + 29].std_surname }}
                  </div>
                </td>
                <td>
                  <div v-if="sumData.mainData[n + 29]">
                    <div v-if="sumData.mainData[n + 29].totalScore < 80" class="text-right alert-danger">
                      {{ sumData.mainData[n + 29].totalScore }}
                    </div>
                    <div v-else class="text-right">
                      {{ sumData.mainData[n + 29].totalScore }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="sign">
                <td>
                  <div class="text-right">ลงชื่อ</div>
                </td>
                <td colspan="2">
                  <div class="text-center">
                    <br />
                    ............................................................<br />
                    (...................................................................)
                  </div>
                </td>
                <td>
                  <div class="text-left">เจ้าหน้าที่สารสนเทศ</div>
                </td>
                <td>
                  <div class="text-right">ลงชื่อ</div>
                </td>
                <td colspan="2">
                  <div class="text-center">
                    <br />
                    ............................................................<br />
                    (...................................................................)
                  </div>
                </td>
                <td>
                  <div class="text-left">เจ้าหน้าที่สารสนเทศ</div>
                </td>
              </tr>
            </tbody>
          </table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <CRow v-else>
    <CCol class="text-center">
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from "../charts/index.js";
import Datepicker from "vuejs-datepicker";
import { Printd } from "printd";
import XLSX from "xlsx"; // import xlsx;

export default {
  name: "WidgetsDropdown",
  components: { CChartLineSimple, CChartBarSimple, Datepicker, Printd, XLSX },
  data() {
    return {
      semesterSum: null,
      sumData: null,
      monthSum: null,
      nowMonth: null,
      nowYear: null,
      appointmantData: null,
      userData: this.$cookies.get("user"),
      levelInfo: null,
      classInfo: null,
      rowsExcel: []
    };
  },
  mounted() {
    //FETCH CLASS DROPDOWN
    this.axios
      .get(
        this.$hostUrl +
        "php_action/classAPI.php?txtAction=getAll&schoolId=" +
        this.userData.user_address
      )
      .then((response) => {
        this.classInfo = response.data.mainData;
        //console.log(response.data);
      });



    //FETCH LEVEL DROPDOWN
    this.axios
      .get(this.$hostUrl + "php_action/levelAPI.php?txtAction=getAll")
      .then((response) => {
        //console.log(response.data);
        this.levelInfo = response.data.mainData;

      });



    //FETCH APPOINTMENT IN SEMESTER BY SCHOOL
    this.axios
      .get(
        this.$hostUrl +
        "php_action/appointmentAPI.php?txtAction=getAll&schoolId=" +
        this.userData.user_address
      )
      .then((response) => {
        this.appointmantData = response.data;
        //console.log(this.appointmantData);
      })
      .finally(() => { });

    //DATE DATA
    let d = new Date();
    this.nowYear = d.getFullYear() + 543;
    var thmonth = new Array(
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม"
    );
    this.nowMonth = thmonth[d.getMonth()];
  },
  methods: {
    getClassroomStat() {
      const form = document.getElementById("formSearchClassroom");
      const formData = new FormData(form);
      formData.append("txtAction", "getStudentScoreInClass");
      formData.append("schoolId", this.userData.user_address);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.sumData = response.data;
          this.rowsExcel = response.data.excelData;
          //console.log(this.sumData);
        });

    },
    printD() {
      const cssText = `
      @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');
  h1 h4 {
    color: black;
    font-family: kanit;
  }
  .text-red{
    color: red;
  }
.table th,
.table td {

    vertical-align: top;
    font-size: 0.7rem;
    font-family: kanit;
    border: 1px solid black !important;
}
table{
  width:100% !important;
  border-spacing: 0px !important;
}
.sign tr{
  border: none !important;
}
.bg-red{
    background-color: #ef5550;
}
h4,
.h4 {
    font-size: 1.25rem
}
h5,
.h5 {
    font-size: 1.0rem
}
h3,
.h3 {
    font-size: 1.5rem;
    font-family: kanit
}
.text-right {
    text-align: right !important
}
.text-center {
    text-align: center !important;
    font-family: kanit;
}

`;
      const d = new Printd();
      d.print(document.getElementById("single-info"), [cssText]);
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
  },
};
</script>
